import React from 'react'
import {
  Paper,
  Button,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { withStyles } from '@mui/styles'
// import Euro from '@mui/icons-material/Euro'
import { ModalCust } from './Lib'
import {
  gridItem,
  gridSpacer,
  paperStyle,
  gridWrapPaper,
  showCaseListHeader,
  showCaseListTypeBig,
  showCaseListType,
  menuDividerSmal,
  showCaseItemName,
  showCaseItemParents,
  higTopDivider,
  higBotDivider,
  cartDivider,
  cartAdWrap,
  cartAdHeadline,
  cartAdSubline,
  cartAdVideo,
  showCaseGreeting,
  showCaseName,
  showCaseBalance,
  menuHeader,
  menuHeaderCart,
  menuHeaderCartHig,
  menuList,
  menuListText,
  menuListHeader,
  menuListHeaderSmal,
  menuDivider,
  cartMemberName,
  cartMemberBalance,
  menuListHighlightCart,
  menuListHighlightCartMenu,
  menuListHighlight,
  menuListHighlightSpannabisCart,
  menuListMenuScreen,
  menuListMenuScreenCart,
  menuListSec,
  menuListSecCart,
  menuHeaderCartChoiceSub,
  menuListChoice
} from './Style'
import __ from '../util'

class Cart extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.state = {
      cartSelected: undefined,
      menu: undefined,
      staffCarts: []
    }
    this.getStaffCarts = this.getStaffCarts.bind(this)
    this.getCart = this.getCart.bind(this)
    this.logout = this.logout.bind(this)
    this.getMenu = this.getMenu.bind(this)
    this.handleLogoTaps = this.handleLogoTaps.bind(this)
    this.logoutCounter = 0
    this.updateRunning = false
  }

  async handleLogoTaps () {
    this.logoutCounter = this.logoutCounter +1
    if(this.logoutCounter > 5) {
      this.logout()
    }
  }

  async logout () {
    clearInterval(this.interval)
    this.cx.core.clear()
    this.props.history.push('/login')
  }

  async getCart (owner) {
    let cart

    //console.log('hi get cart', this.interval)
    if(!this.updateRunning) {
      // console.log('shoot');
      this.updateRunning = true
      try {
        [cart] = await Promise.all([
          this.cx.cart.getCart(owner)
        ])
      } catch (e) {
        if (__.cfg('isDev')) throw e
        /*const emsg = e.message
        __.clearSto()
        this.setState({ err: emsg })
        throw e*/
        console.log(e)
      }
      this.setState({cart})
      this.updateRunning = false
    } else {
      // console.log('skip');
    }
    if(owner && !this.interval) {
        this.interval = setInterval(() => this.getCart(owner), __.cfg('refreshCartView'))
    }
    this.logoutCounter = 0
  }

  async getStaffCarts () {
    let staffCarts
    try {
      [staffCarts] = await Promise.all([
        this.cx.cart.getStaffCarts()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    if(Array.isArray(staffCarts) && staffCarts.length > 0) {
      this.setState({
        staffCarts
      })
    }
  }


  async getMenu () {
    let menu

    try {
      [menu] = await Promise.all([
        this.cx.core.getMenu()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    if(Array.isArray(menu)) {
      this.setState({
        menu
      })
    }

  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = '#fff'

    Object.assign(this, __.initView(this, 'cart'))

    await Promise.all([
      this.getStaffCarts(),
      this.getMenu()
    ])
  }

  componentWillUnmount() {
    // console.log('hi unmount', this.interval)
    if(this.interval) {
      clearInterval(this.interval)
    }
  }

  render () {
    // console.log('render state', this.state)
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.cartSelected && this.state.cart && this.state.cart.showCase) {
      return (
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white', padding: '30px'}} square>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                alt='Btm Div'
                className={this.props.classes.higTopDivider}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{padding:15}}>
              <Typography variant='body1' align='center'>
                <img style={{maxWidth:'100%', maxHeight: '300px'}} src={this.state.cart.showCase.imageLink} alt={this.state.cart.showCase.name}/>
              </Typography>
              <Typography
                variant='h3'
                align='center'
                className={this.props.classes.showCaseItemName}
                noWrap
                style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
              >
                {this.state.cart.showCase.name}
              </Typography>
              {this.state.cart.showCase.parents &&
                <Typography variant='h5' align='center' className={this.props.classes.showCaseItemParents}>
                  {this.state.cart.showCase.parents}
                </Typography>}
              {this.state.cart.showCase.desc &&
                <Typography variant='h5' align='center' className={this.props.classes.showCaseItemParents}>
                  {this.state.cart.showCase.desc}
                </Typography>}
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <List className={this.props.classes.menuList}>
                {this.state.cart.showCase.phenotype && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Species</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h3'
                            className={this.props.classes.showCaseListTypeBig}
                          >
                            {this.state.cart.showCase.phenotype}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                {this.state.cart.showCase.manufacturing && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Manufacturing</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            {this.state.cart.showCase.manufacturing}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                {this.state.cart.showCase.aroma && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Aroma</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            {this.state.cart.showCase.aroma}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                {this.state.cart.showCase.effect && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Effect</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            {this.state.cart.showCase.effect}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}
                {this.state.cart.showCase.thc && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>THC</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            ≈ {this.state.cart.showCase.thc} %
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                {this.state.cart.showCase.cbd && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>CBD</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            ≈ {this.state.cart.showCase.cbd} %
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                {this.state.cart.showCase.cbg && <span>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>CBG</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            ≈ {this.state.cart.showCase.cbg} %
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </span>}

                <ListItem className={this.props.classes.menuList}>
                  <ListItemText
                    disableTypography
                    className={this.props.classes.menuListText}
                    primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Cost</Typography>}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant='h4'
                          className={this.props.classes.showCaseListType}
                        >
                          {__.formatNumber(this.state.cart.showCase.cost)} €
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider className={this.props.classes.menuDividerSmal}/>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <img
              src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
              alt='Btm Div'
              className={this.props.classes.higBotDivider}
              style={{marginTop: this.state.cart.showCase.manufacturing ? '100px' : '110px'}}
            />
          </Grid>
        </Paper>
      )
    } else if (this.state.cart && this.state.cart.showMenu && this.state.cart.showMenu.length > 0) {
      return (
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
          <Grid container justifyContent='center' spacing={2} style={{paddingTop: 10}}>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                alt='Btm Div'
                className={this.props.classes.higTopDivider}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' align='center' className={this.props.classes.menuHeader} style={{borderBottom: 0}}>Drinks</Typography>
            </Grid>
            {this.state.cart.showMenu.map((item) => <Grid key={item.id} item xs={3}>
              <List className={this.props.classes.menuList}>
                <div key={item.idItem}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCart : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeader}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecCart}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </div>
              </List>
            </Grid>)}
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
                alt='Btm Div'
                className={this.props.classes.higBotDivider}
                style={{marginTop:'120px'}}
              />
            </Grid>
          </Grid>
        </Paper>)
} else if (this.state.cart && this.state.cart.showMenu3 && this.state.cart.showMenu3.length > 0) {
  return (
    <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
      <Grid container justifyContent='center' spacing={2} style={{paddingTop: 10}}>
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-top.jpg'
            alt='Btm Div'
            className={this.props.classes.higTopDivider}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeader} style={{borderBottom: 0}}>Drinks Events</Typography>
        </Grid>
        {this.state.cart.showMenu3.map((item) => <Grid key={item.id} item xs={3}>
          <List className={this.props.classes.menuList}>
            <div key={item.idItem}>
              <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCart : this.props.classes.menuList)}>
                <ListItemText
                  disableTypography
                  className={this.props.classes.menuListText}
                  primary={
                    <React.Fragment>
                      <Typography
                        variant='body2'
                        className={this.props.classes.menuListHeader}
                        color='textPrimary'
                      >
                        {item.name}
                      </Typography>
                      <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                    </React.Fragment>
                  }
                  secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecCart}>{item.parents}</Typography>}
                />
              </ListItem>
              <Divider className={this.props.classes.menuDivider} />
            </div>
          </List>
        </Grid>)}
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
            alt='Btm Div'
            className={this.props.classes.higBotDivider}
            style={{marginTop:'120px'}}
          />
        </Grid>
      </Grid>
    </Paper>)
  } else if (this.state.cart && this.state.cart.showMenu1 && this.state.cart.showMenu1.length > 0) {
  return (
    <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
      <Grid container justifyContent='center' spacing={2} style={{paddingTop: 10}}>
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-top.jpg'
            alt='Btm Div'
            className={this.props.classes.higTopDivider}
            style={{marginBottom: 24, marginTop: 12}}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart}>Indica</Typography>
          <List className={this.props.classes.menuList}>
          {this.state.cart.showMenu1.filter((el) => el.idCategory === 1 && el.activeChoice !== 1).map((item) => <div key={item.idItem}>
            <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      {item.name}
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </div>)}
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart}>Hybrid</Typography>
          <List className={this.props.classes.menuList}>
            {this.state.cart.showMenu1.filter((el) => el.idCategory === 2 && el.activeChoice !== 1).map((item) => <div key={item.idItem}>
              <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                <ListItemText
                  disableTypography
                  className={this.props.classes.menuListMenuScreenCart}
                  primary={
                    <React.Fragment>
                      <Typography
                        variant='body2'
                        className={this.props.classes.menuListHeader}
                        color='textPrimary'
                      >
                        {item.name}
                      </Typography>
                      <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                    </React.Fragment>
                  }
                  secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
                />
              </ListItem>
              <Divider className={this.props.classes.menuDivider} />
            </div>)}
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart}>Sativa</Typography>
          <List className={this.props.classes.menuList}>
            {this.state.cart.showMenu1.filter((el) => el.idCategory === 3 && el.activeChoice !== 1).map((item) => <div key={item.idItem}>
              <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                <ListItemText
                  disableTypography
                  className={this.props.classes.menuListMenuScreenCart}
                  primary={
                    <React.Fragment>
                      <Typography
                        variant='body2'
                        className={this.props.classes.menuListHeader}
                        color='textPrimary'
                      >
                        {item.name}
                      </Typography>
                      <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                    </React.Fragment>
                  }
                  secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
                />
              </ListItem>
              <Divider className={this.props.classes.menuDivider} />
            </div>)}
          </List>
        </Grid>
        
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
            alt='Btm Div'
            className={this.props.classes.higBotDivider}
            style={{marginTop: 48}}
          />
        </Grid>
      </Grid>
    </Paper>)
} else if (this.state.cart && this.state.cart.showMenu2 && this.state.cart.showMenu2.length > 0) {
  return (
    <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
      <Grid container justifyContent='center' spacing={2} style={{paddingTop: 10}}>
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-top.jpg'
            alt='Btm Div'
            className={this.props.classes.higTopDivider}
             style={{marginBottom: 0}}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart}>Pre-Rolled Joints</Typography>
          <List className={this.props.classes.menuList}>
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      JOINT LIADO ESTÁNDAR
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>3 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>A partir de las cepas favoritas de nuestros socios</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      JOINT PACK
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>9 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>4 joint liados por el precio de 3</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      CBD Joint Liado
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>3 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>Elaborado con nuestras mejores cepas de CBD</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </List>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart} style={{marginTop: 102}}>Specials</Typography>
          <List className={this.props.classes.menuList}>
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      Vape Pen
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{'>'}45 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>Batería recargable y cartucho de THC de 0,5 ml</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      Extracciones
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{'>'}27 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>Rosin casero y Wax de alta concentración</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
            <ListItem className={this.props.classes.menuList}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      Moonrocks
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>25 €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>Flores recubiertas de Rosin y polen</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart}>Hashish</Typography>
          {this.state.cart.showMenu2.filter((el) => el.idCategory === 4 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
            <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      {item.name}
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </List>)}
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCartHig}>CBD</Typography>
          {this.state.cart.showMenu2.filter((el) => el.idCategory === 11 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
            <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      {item.name}
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </List>)}
          <Typography variant='h2' align='center' className={this.props.classes.menuHeaderCart} style={{marginTop: 102}}>Premium Hash</Typography>
          {this.state.cart.showMenu2.filter((el) => el.idCategory === 19 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
            <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
              <ListItemText
                disableTypography
                className={this.props.classes.menuListMenuScreenCart}
                primary={
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={this.props.classes.menuListHeader}
                      color='textPrimary'
                    >
                      {item.name}
                    </Typography>
                    <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                  </React.Fragment>
                }
                secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSec}>{item.parents}</Typography>}
              />
            </ListItem>
            <Divider className={this.props.classes.menuDivider} />
          </List>)}
        </Grid>
        <Grid item xs={12}>
          <img
            src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
            alt='Btm Div'
            className={this.props.classes.higBotDivider}
            style={{marginTop: 0}}
          />
        </Grid>
      </Grid>
    </Paper>)
    } else if (this.state.cartSelected && this.state.cart && this.state.cart.showComplete) {
      return (
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
          <Grid container justifyContent='center' spacing={0} style={{height: '100vh', padding: 40}}>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                alt='Btm Div'
                className={this.props.classes.higTopDivider}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' align='center' className={this.props.classes.showCaseGreeting}>Thank you!</Typography>
              <Typography variant='h5' align='center' className={this.props.classes.showCaseName}>See you soon</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' align='center'>
                <img style={{maxHeight: '192px'}} src='//cdn.higcsc.com/higlogo-black-trans.png' alt='HIG CSC LOGO' onClick={async event => this.handleLogoTaps(event)}/>
              </Typography>
              <img
                src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
                alt='Btm Div'
                className={this.props.classes.higBotDivider}
                style={{marginTop:'140px'}}
              />
            </Grid>
          </Grid>
        </Paper>
      )
    } else if (this.state.cartSelected && this.state.cart && !this.state.cart.showCase) {
      // TODO random element for ad
      const cartAd = __.cfg('cartAds')[this.state.cart.cartAd || 0]

      return (
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background:'#fff'}} square>
          {this.state.cart.items.length === 0 && <Grid container justifyContent='center' spacing={0} style={{height: '100vh', padding: 40}}>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                alt='Btm Div'
                className={this.props.classes.higTopDivider}
                style={{marginBottom: 200}}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' align='center' className={this.props.classes.showCaseGreeting}>Welcome</Typography>
              <Typography variant='h5' align='center' className={this.props.classes.showCaseName}>{this.state.cart.firstNameMember}</Typography>
              <div style={{textAlign: 'center', marginTop: 20}}>
                <Typography variant='body1' align='center' display='inline' className={this.props.classes.showCaseBalance}>Your Balance {__.formatNumber(this.state.cart.balanceMember)} €</Typography>
                {(this.state.cart.balanceMember < 0.1) && <Typography variant='body1' align='center' style={{marginTop: 20, color: '#000'}}>Please talk to us to increase balance...</Typography>}
              </div>
            </Grid>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
                alt='Btm Div'
                className={this.props.classes.higBotDivider}
                style={{marginTop: 160}}
              />
            </Grid>
          </Grid>}
          {this.state.cart.items.length > 0 && <Grid container spacing={0}>
            <div style={{display: 'block', width: '100%', marginBottom: 24, marginTop: 24}}>
                <Typography variant='h5' display='inline' className={this.props.classes.cartMemberName}>Hello {this.state.cart.firstNameMember}!</Typography>
                <Typography variant='body1' display='inline' className={this.props.classes.cartMemberBalance}>Your Balance {__.formatNumber(this.state.cart.balanceMember)} €</Typography>
              </div>
            {this.state.cart.items.length !== 0 && <Grid item xs={12}>
              <TableContainer component={Paper} className={this.props.classes.warehouseSpacer}>
                <Table aria-label='simple table'>
                  <caption style={{textAlign:'right'}}>{__.formatNumber(this.state.cart.cartSum)} € total</caption>
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cart && this.state.cart.items.length > 0 && this.state.cart.items.map((item) => {
                      return <TableRow key={item.idItem}>
                        <TableCell>
                          {item.category}
                        </TableCell>
                        <TableCell>
                          {item.name}
                        </TableCell>
                        <TableCell>
                          {__.formatNumber(item.cost)} €
                        </TableCell>
                        <TableCell>
                          {item.quantity}
                        </TableCell>
                        <TableCell>
                          {__.formatNumber(item.quantity * item.cost)} €
                        </TableCell>
                      </TableRow>}
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>}
          {this.state.cart.items.length > 0 && <div>
            <Grid container spacing={2} style={{paddingTop: 41}}>
              <Grid item xs={12}>
                <img
                  src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                  alt='Cart Div'
                  className={this.props.classes.cartDivider}
                />
              </Grid>
              <Grid item xs={6}>
                <div className={this.props.classes.cartAdWrap}>
                  <Typography variant='h2' className={this.props.classes.cartAdHeadline}>{cartAd.headline}</Typography>
                  <Typography variant='h3' className={this.props.classes.cartAdSubline}>{cartAd.subline}</Typography>
                </div>
              </Grid>
              <Grid item xs={6} align='center'>
                <video id="colavideo" width="392" height="220" className={this.props.classes.cartAdVideo} src={__.cfg('videoCdnUrl') + cartAd.video} type='video/mp4' autoPlay loop muted />
              </Grid>
            </Grid>
          </div>}
        </Grid>}
      </Paper>
      )
    } else if (this.state.cartSelected) {
      return (
        <Paper className={this.props.classes.paperStyle} square elevation={0} style={{background:'#fff'}}>
          <Grid container justifyContent='center' spacing={0} style={{height: '100vh', padding: 40}}>
            <Grid item xs={12}>
              <img
                src='//cdn.higcsc.com/menu/hig-div-top.jpg'
                alt='Btm Div'
                className={this.props.classes.higTopDivider}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' align='center' className={this.props.classes.showCaseGreeting}>Welcome</Typography>
              <Typography variant='h5' align='center' className={this.props.classes.showCaseName}>Please tell us your member ID</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' align='center'>
                <img style={{maxHeight: '192px'}} src='//cdn.higcsc.com/higlogo-black-trans.png' alt='HIG CSC LOGO' onClick={async event => this.handleLogoTaps(event)}/>
              </Typography>
              <img
                src='//cdn.higcsc.com/menu/hig-div-bot.jpg'
                alt='Btm Div'
                className={this.props.classes.higBotDivider}
                style={{marginTop:'140px'}}
              />
            </Grid>
          </Grid>
        </Paper>
      )
    } else {
      return (
        <Grid justifyContent='center' container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Paper className={this.props.classes.paperStyle} style={{marginTop: 32}}>
              <Typography gutterBottom variant='h5'>Select staff account for cart view</Typography>
              {this.state.staffCarts.filter(o => o.name.indexOf('Lobby') === -1).filter(o => o.name.indexOf('Dispensario Ipad') === -1).map((staffCart, i) =>
                <Button
                  fullWidth
                  variant='outlined'
                  key={i}
                  style={{marginTop: 12, marginBottom: 12}}
                  onClick={async () => {
                    this.setState({
                      cartSelected: staffCart
                    })
                    this.getCart(staffCart.email)
                  }}
                >
                  {staffCart.name}
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      )
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    gridWrapPaper,
    showCaseListHeader,
    showCaseListTypeBig,
    showCaseListType,
    menuDividerSmal,
    showCaseItemName,
    showCaseItemParents,
    higTopDivider,
    higBotDivider,
    cartDivider,
    cartAdWrap,
    cartAdHeadline,
    cartAdSubline,
    cartAdVideo,
    showCaseGreeting,
    showCaseName,
    showCaseBalance,
    menuHeader,
    menuHeaderCart,
    menuHeaderCartHig,
    menuList,
    menuListText,
    menuListHeader,
    menuListHeaderSmal,
    menuDivider,
    cartMemberName,
    cartMemberBalance,
    menuListHighlightCart,
    menuListHighlightCartMenu,
    menuListHighlight,
    menuListHighlightSpannabisCart,
    menuListMenuScreen,
    menuListMenuScreenCart,
    menuListSec,
    menuListSecCart,
    menuHeaderCartChoiceSub,
    menuListChoice
  })(Cart)
