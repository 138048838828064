import packageJson from '../package.json'
const d = {
  isDev: process.env.NODE_ENV === 'development'
}

Object.assign(d, {
  version: packageJson.version,
  apiUrl: 'https://api.higcsc.com/v1',
  homeUrl: 'https://higcsc.com',
  btcpayUrl: 'https://btcpay.davidcoen.it/apps/2yt3QNi5snqazyZLv5bXmdUEQdsz/pos',
  qrCdnUrl: 'https://qrcdn.gardenbarcelona.es/qr/',
  videoCdnUrl: 'https://cdn.higcsc.com/video/',
  scanSound: 'https://cdn.higcsc.com/sound/scan-sound-shrink.mp3',
  lstMax: 50,
  maxName: 35,
  maxLow: 25,
  maxElip: 5,
  maxHigh: 100,
  minPw: 8,
  maxPw: 36,
  tsYearFormat: 'DD-MM-YYYY',
  tsDateFormat: 'DD-MM-YYYY HH:mm',
  tsDateFormatDetail: 'DD-MM-YYYY HH:mm:ss',
  tsFormat: 'HH:mm',
  tsFormatDetail: 'HH:mm:ss',
  dfltLocale: 'en-en',
  membershipMonthValid: 6,
  refreshCartView: 1500,
  refreshMenuView: 60000,
  lobbyAccounts: ['lobby@higcsc.com', 'lobby2@higcsc.com'],
  cartAds: [
    {
      headline: 'Thirsty? Get Refreshed!',
      subline: 'Add an ice cold drink to your cart now.',
      video: 'cola.mp4'
    },
    {
      headline: 'Brewed for Your Bliss!',
      subline: 'Pair your favorite strain with a hot coffee.',
      video: 'coffee.mp4'
    },
    {
      headline: 'Wings for Your Session!',
      subline: 'Elevate your cannabis experience with a Red Bull.',
      video: 'redbull.mp4'
    },
    {
      headline: 'Purify Your Puffs!',
      subline: 'Activated Carbon Filters: Experience smoother, cleaner smoke.',
      video: 'purizefilter.mp4'
    }
  ]
})

const cfg = key => (key == null ? d : d[key])
export default cfg
