import React from 'react'
// import { Redirect } from 'react-router-dom'
import Box from '@mui/material/Box'
import * as mo from 'moment'
import { Divider, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Grid, TableBody, TableRow, TableHead, Table, TableContainer, TableCell, Select, Chip, IconButton, Button, Paper, LinearProgress, FormControl, InputLabel, MenuItem, Typography, TextField} from '@mui/material'
import { withStyles } from '@mui/styles'
import { Snack, TopBar, ModalCust, TosContract } from './Lib'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select'
import ClearIcon from '@mui/icons-material/Clear'
import { ArrowBack, AddShoppingCart, ExpandMore, Print } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import { QrReader } from 'react-qr-reader'
import { styleGuide, colorFormat, gridWrap, gridWrapPaper, paperStyle, dividerStyle, dashTable, menuList, menuListTextTv, menuDivider, menuDividerSmal, showCaseListHeader, showCaseListType, showCaseListTypeBig, showCaseItemName, showCaseItemParents, menuHeader, menuHeaderHig, menuListText, menuListHeader, menuListHeaderSmal, menuListSecCart, menuListHighlightCartMenu, menuListHighlightSpannabisCart, qrCodeWrap, menuListMenuScreen, menuListHeaderTv, menuListSecTv, menuListHighlightCart, menuListChoice } from './Style'
import __ from '../util'
const scanSound = new Audio()
scanSound.autoplay = true;

function QuickSearchToolbarMember(props) {
  return (
    <TextField
      fullWidth
      value={props.value}
      onChange={props.onChange}
      placeholder="Search for member..."
      InputProps={{
        endAdornment: (
          <IconButton
            title='Clear'
            aria-label='Clear'
            size='small'
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        )
      }}
    />
  );
}

function QuickSearchToolbarItem(props) {
  return (
    <TextField
      fullWidth
      value={props.value}
      onChange={props.onChange}
      placeholder="Search for item..."
      InputProps={{
        endAdornment: (
          <IconButton
            title='Clear'
            aria-label='Clear'
            size='small'
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        )
      }}
    />
  );
}

class DashView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      scannerActive: false,
      scannerBtnDisabled: false,
      qrSelected: undefined,
      qrCamFacingMode: true,
      members: [],
      items: [],
      cart: [],
      cartSelection: [],
      showCaseSelection: [],
      cartSum: 0,
      searchTextMember: '',
      searchTextItem: '',
      pageMember: 0,
      pageItem: 0,
      pageSizeMember: 5,
      pageSizeItem: 5,
      alert: undefined,
      snack: undefined,
      checkinFired: false,
      donateAmount: '',
      donationType: 1,
      imgModal: undefined,
      imgModalType: undefined,
      showCase: undefined,
      memberSelected: [],
      memberHistory: undefined,
      emissionDone: false,
      emitClicked: false,
      tosModal: false,
      tosMember: undefined,
      menu: undefined,
      menu1: undefined,
      menu2: undefined,
      faceImgQuick: undefined,
      donations: undefined,
      showDonatePop: false,
      cartAd: Math.floor(Math.random()*__.cfg('cartAds').length)
    }
    this.state = {
      ...this.reset
    }
    this.quickSearchRowsMember = ['idMember', 'hashId', 'email', 'firstName', 'lastName']
    this.quickSearchRowsItem = ['idItem', 'category', 'name']
    this.getMembers = this.getMembers.bind(this)
    this.getItems = this.getItems.bind(this)
    this.getFaceImgQuick = this.getFaceImgQuick.bind(this)
    this.getImage = this.getImage.bind(this)
    this.showTos = this.showTos.bind(this)
    this.showQr = this.showQr.bind(this)
    this.checkin = this.checkin.bind(this)
    this.donate = this.donate.bind(this)
    this.showDonatePop = this.showDonatePop.bind(this)
    this.deselect = this.deselect.bind(this)
    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    this.delete = this.delete.bind(this)
    this.playScanSound = this.playScanSound.bind(this)
    this.updateCart = this.updateCart.bind(this)
    this.getHistory = this.getHistory.bind(this)
    this.showMenu = this.showMenu.bind(this)
    this.showDonations = this.showDonations.bind(this)
    this.handleCloseDonations = this.handleCloseDonations.bind(this)
    this.handleCloseMemberHistory = this.handleCloseMemberHistory.bind(this)
    this.handleDonationTypeChange = this.handleDonationTypeChange.bind(this)
    this.handleRowUpdate = this.handleRowUpdate.bind(this)
    this.handleAddCart = this.handleAddCart.bind(this)
    this.handleCloseHandout = this.handleCloseHandout.bind(this)
    this.handleClearCart = this.handleClearCart.bind(this)
    this.handleCloseShowCaseModal = this.handleCloseShowCaseModal.bind(this)
    this.handleCloseTosModal = this.handleCloseTosModal.bind(this)
    this.handleFinalizeCart = this.handleFinalizeCart.bind(this)
    this.handleEmitItem = this.handleEmitItem.bind(this)

    this.members = []
  }

  handleCloseImgModal () {
    this.setState({
      imgModal: undefined
    })
  }

  handleCloseTosModal () {
    this.setState({
      tosModal: false,
      tosMember: undefined,
      signatureDataUri: undefined
    })
  }

  handleCloseDonations () {
    this.setState({
      donations: undefined
    })
  }

  handleCloseMemberHistory () {
    this.setState({
      memberHistory: undefined
    })
  }

  async handleCloseHandout () {
    // await this.handleClearCart()

    await this.deselect()

    this.setState({
      scannerActive: false,
      scannerBtnDisabled: false,
      snack: 'Member session cleared',
      handoutModal: undefined,
      emissionDone: false,
      cart: [],
      cartSum: 0,
      cartSelection: [],
      emitClicked: false,
      faceImgQuick: undefined,
      cartAd: Math.floor(Math.random()*__.cfg('cartAds').length) //generate new cart ad
    })
  }

  handleCloseShowCaseModal () {
    this.setState({
      showCase: undefined
    })
    this.cx.cart.clearShowCase(__.getJsonSto('core').loginemail)
  }

  async handleEmitItem (event, item) {
    const cartEdit = this.state.cart.find(e => e.idItem === item.idItem)
    const cartIndex = this.state.cart.findIndex(e => e.idItem === item.idItem)
    cartEdit.handout = true

    const newCart = this.state.cart
    newCart[cartIndex] = cartEdit

    const itemsHandout = newCart.filter(e => e.handout === true)

    // load users again (update balance)
    if(itemsHandout.length === this.state.cart.length) {
        this.getMembers()
    }

    this.setState({
      cart: newCart,
      emissionDone: itemsHandout.length === this.state.cart.length
    })
  }

  handleDonationTypeChange(event) {
    this.setState({
      donationType: event.target.value
    });
  }

  handleRowUpdate (updatedElement) {
    this.items = this.items.map(e => {
      if(e.idItem === updatedElement.idItem) {
        return updatedElement
      } else {
        return e
      }
    })
    return updatedElement
  }

  async handleAddCart () {
    const selectionArray = this.state.showCaseSelection && this.state.showCaseSelection.length > 0 ? this.state.showCaseSelection : this.state.cartSelection

    const newItems = selectionArray.map((idItem) => {
      const stockItem = this.items.find(si => si.idItem === idItem)
      let quantity = stockItem.quantity ? parseFloat(stockItem.quantity.replace(',', '.').replace(/[^0-9,.]+/g, '')) : 1

      return {
        idItem,
        quantity: quantity,
        idCategory: stockItem.idCategory,
        category: stockItem.category,
        cost: stockItem.cost,
        name: stockItem.name,
        total: stockItem.total || stockItem.cost
      }
    })
    const newCart = [...new Map(this.state.cart.concat(newItems).map((item) => [item['idItem'], item])).values()]
    const cartSum = newCart.map(item => item.quantity * item.cost).reduce((prev, curr) => prev + curr, 0)

    this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, cartSum, newCart, this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)

    this.setState({
      cart: newCart,
      cartSum: cartSum,
      cartSelection: [],
      showCaseSelection: [],
      menu: undefined,
      menu1: undefined,
      menu2: undefined,
      scannerActive: false,
      scannerBtnDisabled: false
    })
  }

  handleClearCart () {
    this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, 0, [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, null, null)

    this.setState({
      cart: [],
      cartSum: 0,
      cartSelection: [],
      emitClicked: false,
      cartAd: Math.floor(Math.random()*__.cfg('cartAds').length), //generate new cart ad
      scannerActive: false,
      scannerBtnDisabled: false
    })
  }

  handleChangeCart (event, type, item) {
    if(this.state.alert) {
      return
    }
    const cartEdit = this.state.cart.find(e => e.idItem === item.idItem)
    const cartIndex = this.state.cart.findIndex(e => e.idItem === item.idItem)

    let quantity = cartEdit.quantity
    let total = cartEdit.total

    if (type === 'quantity') {
      quantity = event.target.value ? event.target.value.replace(',', '.').replace(/[^0-9,.]+/g, '') : ''
      cartEdit.quantity = quantity
      cartEdit.total = parseFloat(quantity) * parseFloat(cartEdit.cost)
    }

    if (type === 'total') {
      total = event.target.value ? event.target.value.replace(',', '.').replace(/[^0-9,.]+/g, '') : ''
      cartEdit.total = total
      cartEdit.quantity = Math.floor((parseFloat(total) / parseFloat(cartEdit.cost)) * 10000) / 10000;
    }

    const newCart = this.state.cart
    newCart[cartIndex] = cartEdit

    const cartSum = newCart.map(item => item.quantity * item.cost).reduce((prev, curr) => prev + curr, 0)

    this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, cartSum, newCart, this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)

    this.setState({
      cart: newCart,
      cartSum
    })
  }

  handleRmItemCart (event, item) {

    let cart = this.state.cart
    let cartSum = 0

    if(cart) {
      cart = cart.filter(el => el.idItem !== item.idItem)
      cartSum = cart.map(item => item.quantity * item.cost).reduce((prev, curr) => prev + curr, 0)
    }

    this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, cartSum, cart, this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)

    this.setState({
      cart,
      cartSum
    })
  }

  async handleFinalizeCart () {
    // check for quantity 0 product
    if (this.state.cart && this.state.cart.length > 0) {
      let isQuantZero = false

      for (const item of this.state.cart) {
        const quant = parseFloat(item.quantity) || 0

        if(quant === 0 || quant < 0.1) {
          isQuantZero = true
        }
      }

      if(isQuantZero) {
        this.setState({snack: 'Emit NOT possible! Check item quantity!'})
        return
      }
    }

    this.setState({emitClicked: true})
    try {
      await this.cx.cart.finalizeCart(this.state.memberSelected[0], this.state.cart, __.getJsonSto('core').loginemail)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    await this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum, this.state.cart, this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, true)

    this.setState({
      scannerActive: false,
      scannerBtnDisabled: false,
      handoutModal: true
    })
  }

  async showDesc (idItem, name, parents, desc, imageLink, phenotype, effect, aroma, manufacturing, thc, cbd, cbg, cost, cart, cartSum) {
    if(this.state.alert) {
      return
    }

    const showCase = {
      idItem,
      name,
      parents,
      desc,
      imageLink,
      phenotype,
      effect,
      aroma,
      manufacturing,
      thc,
      cbd,
      cbg,
      cost
    }
    this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, cartSum, cart, this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, showCase, undefined, this.state.menu, this.state.menu1, this.state.menu2)
    this.setState({
      scannerActive: false,
      scannerBtnDisabled: false,
      showCase,
      showCaseSelection: [idItem]
    })
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'dash'))

    await Promise.all([
     this.getMembers(),
     this.getItems()
   ])
  }

  async getMembers () {
    let members, alert

    try {
      [members, alert] = await Promise.all([
        this.cx.dash.getMembers(),
        this.cx.dash.getAlert()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if (alert) {
      this.setState({
        alert
      })
    }

    if(Array.isArray(members) && members.length > 0) {
      this.members = members

      this.setState({
        members
      })
    }
  }

  async getItems () {
    let items

    try {
      [items] = await Promise.all([
        this.cx.dash.getItems()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(Array.isArray(items) && items.length > 0) {
      this.items = items

      this.setState({
        items
      })
    }
  }

  async getFaceImgQuick (type, imageId) {
    let imgResp

    try {
      imgResp = await this.cx.member.getImage(type, imageId)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      faceImgQuick: "data:image/jpg;base64," + __.arrayBufferToBase64(imgResp.imgData.Body.data)
    })
  }

  async getImage (type, imageId) {
    let imgResp

    try {
      imgResp = await this.cx.member.getImage(type, imageId)
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      imgModal: "data:image/jpg;base64," + __.arrayBufferToBase64(imgResp.imgData.Body.data),
      imgModalType: type
    })
  }

  async showTos (member) {
    let imgSig

    try {
      [imgSig] = await Promise.all([
        this.cx.member.getImage('signature', member.signatureImageId)
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    window.scrollTo(0, 0)

    this.setState({
      tosModal: true,
      tosMember: member,
      signatureDataUri: "data:image/png;base64," + __.arrayBufferToBase64(imgSig.imgData.Body.data)
    })
  }

  async showQr (hashId) {
    window.scrollTo(0, 0)

    this.setState({
      scannerActive: false,
      scannerBtnDisabled: false,
      qrSelected: hashId
    })
  }

  async checkin () {
    let checkResult

    try {
      checkResult = await this.cx.member.checkin(__.getJsonSto('core').loginemail, this.state.memberSelected[0])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(checkResult.affectedRows === 0) {
      this.setState({
        snack: 'Check-In not possible - Member not active',
        checkinFired: true
      })
    } else {
      this.setState({
        snack: 'Check-In done',
        checkinFired: true
      })
    }

    this.getMembers()
  }

  async getHistory () {
    let history

    try {
      history = await this.cx.member.getHistory(this.state.memberSelected[0])

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      memberHistory: history
    })
    window.scrollTo(0, 0)
  }

  async showMenu (event, page) {
    let menu

    try {
      menu = await this.cx.core.getMenu(page)

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(this.state.memberSelected[0]) {
      this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum || 0, this.state.cart || [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, undefined, !page ? menu : undefined, page === 1 ? menu : undefined, page === 2 ? menu : undefined, page === 3 ? menu.filter(item => item.idCategory === 17) : undefined)
    } else {
      this.updateCart(__.getJsonSto('core').loginemail, undefined, undefined, this.state.cartAd, 0, [], undefined, undefined, undefined, !page ? menu : undefined, page === 1 ? menu : undefined, page === 2 ? menu : undefined)
    }
    if(page === 1) {
      this.setState({
        menu1: menu
      })
    } else if(page === 2) {
      this.setState({
        menu2: menu
      })
    } else if(page === 3) {
      this.setState({
        menu3: menu.filter(item => item.idCategory === 17)
      })
    } else {
      this.setState({
        menu
      })
    }
  }

  async showDonations () {
    let donations

    try {
      donations = await this.cx.dash.getDonations()

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      donations
    })
  }

  async deselect () {

    try {
      await this.cx.cart.deselect(__.getJsonSto('core').loginemail)

    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      faceImgQuick: undefined,
      memberSelected: [],
      cartSelection: [],
      cart: [],
      cartSum: 0,
      scannerActive: false,
      scannerBtnDisabled: false
    })
    this.requestSearchMember('')
  }

  async activate (idMember, status) {
    this.setState({ scannerActive: false, scannerBtnDisabled: false, loading: true })

    try {
      await this.cx.member.setActive(idMember, status)
      await this.cx.member.checkin(__.getJsonSto('core').loginemail, idMember)
      await this.getMembers()
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(this.state.searchTextMember && this.state.searchTextMember.length > 0) {
        this.requestSearchMember(this.state.searchTextMember.toString() || '')
    }

    this.setState({
      snack: 'User activated & checked-in',
      memberStatusColor: 'green',
      loading: false
    })
  }

  async deactivate (idMember, status) {
    this.setState({ loading: true })

    try {
      await this.cx.member.setActive(idMember, status)
      await this.getMembers()
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(this.state.searchTextMember && this.state.searchTextMember.length > 0) {
        this.requestSearchMember(this.state.searchTextMember.toString() || '')
    }

    this.setState({
      snack: 'User deactivated',
      loading: false,
      memberStatusColor: 'red',
    })
  }


  async delete (idMember) {
    try {
      const history = await this.cx.member.getHistory(this.state.memberSelected[0])
      if(history && history.length > 0) {
        this.setState({
          snack: 'User delete not possible cause existing donation/emission'
        })
        return
      } else {
        await this.cx.member.delete(idMember)
      }
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      snack: 'User deleted',
      memberSelected: [],
      faceImgQuick: undefined,
      cartSelection: [],
      cart: [],
      cartSum: 0
    })
    this.requestSearchMember('')

    this.getMembers()
  }


  showDonatePop () {
    this.setState({
      showDonatePop: true
    })
  }

  async donate () {
    this.setState({
      scannerActive: false,
      scannerBtnDisabled: false,
      loading: true
    })

    try {
      await this.cx.member.donate(this.state.memberSelected[0], this.state.donationType, parseFloat(this.state.donateAmount))
      await this.getMembers()  // update user balance
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    if(this.state.searchTextMember && this.state.searchTextMember.length > 0) {
        this.requestSearchMember(this.state.searchTextMember.toString() || '')
    }

    this.setState({
      snack: 'Donation booked',
      donateAmount: '',
      donationType: 1,
      loading: false
    })
  }

  async playScanSound () {
    // feed real sound and play it (fuck ios)
    scanSound.src = __.cfg('scanSound');
    scanSound.play()
  }

  async updateCart (owner, idMember, firstNameMember, cartAd, cartSum, newItems, balanceMember, showCase, showComplete, menu, menu1, menu2, menu3) {
    if(this.state.alert) {
      return
    }
    if(cartSum === null || balanceMember === null || isNaN(cartSum)) {
      return
    }
    //if same cartSum or 0 ... do nothing (performance tweak)
    if(idMember === this.state.memberSelected[0] && cartSum === 0 && newItems.length !== 0) {
      return
    }
    try {
      await Promise.all([
        this.cx.cart.updateCart(owner, idMember, firstNameMember, cartAd, cartSum, newItems, balanceMember, showCase, showComplete, menu, menu1, menu2, menu3)
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  escapeRegExp (value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/, "\\$&");
  }

  requestSearchMember (searchValue) {
    const searchRegex = new RegExp(this.escapeRegExp(searchValue), 'i');
    const filteredRows = this.members.filter((row) => {
      return this.quickSearchRowsMember.some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });

    this.setState({
      searchTextMember: searchValue,
      members: filteredRows
    })
  }

  requestSearchItem (searchValue) {
    const searchRegex = new RegExp(this.escapeRegExp(searchValue), 'i');
    const filteredRows = this.items.filter((row) => {
      return this.quickSearchRowsItem.some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });

    this.setState({
      searchTextItem: searchValue,
      items: filteredRows
    })
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else if (this.state.showDonatePop) {
      return (
        <ModalCust
          // fullScreen
          lbl={'New donation'}
          onClose={() => this.setState({ showDonatePop: false, donateAmount: '' })}
          actions={[
            {
              lbl: `Donate ${this.state.donateAmount} €`,
              onClick: async () => {
                this.setState({
                  loading: true
                })
                try {
                  await this.donate()
                } catch (e) {
                  if (__.cfg('isDev')) throw e
                  const emsg = e.message
                  __.clearSto()
                  this.setState({ err: emsg })
                  throw e
                } finally {
                  this.setState({
                    showDonatePop: false,
                    loading: false
                  })
                }
              }
            }
          ]}
        >
            <img alt='face img' src={this.state.faceImgQuick} style={{display:'block', maxHeight: '275px', marginRight: 20, float:'left'}} />
            Do you want to accept a donation from member <span style={{fontWeight: 'bold'}}>{this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName} {this.state.memberSelected[0]}</span>
            <br /><br />
            Members account shows {__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)}€ balance, the member wants to do a <span style={{color:'red'}}>{this.state.donateAmount}€</span> donation
        </ModalCust>
      )
    } else if (this.state.qrSelected) {
      return (
        <ModalCust
          // fullScreen
          lbl={'Print QR Code'}
          onClose={() => this.setState({ qrSelected: undefined, emailQr: undefined })}
          actions={[
            {
              lbl: 'Email',
              onClick: async () => {
                try {
                  await Promise.all([
                    this.cx.member.resendQr(this.state.qrSelected)
                  ])
                } catch (e) {
                  if (__.cfg('isDev')) throw e
                  const emsg = e.message
                  __.clearSto()
                  this.setState({ err: emsg })
                  throw e
                } finally {
                  this.setState({
                    qrSelected: undefined,
                    emailQr: undefined,
                    snack: 'QR Code Email sent!'
                  })
                }
              }
            },
            {
              lbl: 'Print',
              onClick: () => {
                const oldTitle = document.title
  
                document.title = `QR Code Member ${this.state.qrSelected}`
  
                const printStyle = document.createElement('style');
                printStyle.setAttribute('id', 'qrPrintStyle');
                printStyle.textContent = `
                  @page {
                    size: 37mm 80mm;
                    margin: 10px; 
                  }

                  @media print {
                    h1, h2, input, button, input[type=button], input[type=submit], input[type=reset] {
                      display: none;
                      visibility: hidden;
                    }

                    img {
                      padding: 20px;
                    }
                  }

                `;

                document.head.appendChild(printStyle);

                window.print()

                document.title = oldTitle
                document.getElementById('qrPrintStyle').remove()
              }
            }
          ]}
        >
          <img src={`${__.cfg('qrCdnUrl')}${this.state.qrSelected}.png`} alt='memberQr' width={256} height={256} style={{margin: '0 auto', display: 'block'}} />
          <Typography variant='body2' align='center' style={{fontSize: '60px'}}>
            {this.state.qrSelected}
          </Typography>
        </ModalCust>
      )
    } else if (this.state.showCase) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noSchedule
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            noUpdate
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleCloseShowCaseModal}
            midTitle='Showcase'
            action={<AddShoppingCart />}
            isActionAllowed={true}
            onClick={() => {
              this.handleAddCart()
              this.handleCloseShowCaseModal()
            }}
          />
          <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white', padding: '40px 0'}} square>
            <Grid container justifyContent='center' spacing={10}>
              <Grid item xs={12} sm={5}>
                {this.state.showCase.imageLink &&
                  <Typography variant='body1' align='center'>
                    <img style={{maxWidth:'100%', maxHeight: '300px'}} src={this.state.showCase.imageLink} alt={this.state.showCase.name}/>
                  </Typography>}

                <Typography variant='h3' align='center' className={this.props.classes.showCaseItemName}>
                  {this.state.showCase.name}
                </Typography>

                {this.state.showCase.parents &&
                  <Typography variant='h5' align='center' className={this.props.classes.showCaseItemParents}>
                    {this.state.showCase.parents}
                  </Typography>}

                {this.state.showCase.desc &&
                <Typography variant='body1' align='center' className={this.props.classes.showCaseItemParents}>
                  {this.state.showCase.desc}
                </Typography>}
              </Grid>
              <Grid item xs={12} sm={5}>
                <List className={this.props.classes.menuList}>
                  {this.state.showCase.phenotype && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Species</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h3'
                              className={this.props.classes.showCaseListTypeBig}
                            >
                              {this.state.showCase.phenotype}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.aroma && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Aroma</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              {this.state.showCase.aroma}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.manufacturing && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Manufacturing</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              {this.state.showCase.manufacturing}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.effect && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Effect</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              {this.state.showCase.effect}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.thc && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>THC</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              ≈ {this.state.showCase.thc} %
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.cbd && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>CBD</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              ≈ {this.state.showCase.cbd} %
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  {this.state.showCase.cbg && <span>
                    <ListItem className={this.props.classes.menuList}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListTextTv}
                        primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>CBG</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant='h4'
                              className={this.props.classes.showCaseListType}
                            >
                              ≈ {this.state.showCase.cbg} %
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider className={this.props.classes.menuDividerSmal}/>
                  </span>}

                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListTextTv}
                      primary={<Typography variant='h5' display='block' className={this.props.classes.showCaseListHeader}>Cost</Typography>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant='h4'
                            className={this.props.classes.showCaseListType}
                          >
                            {__.formatNumber(this.state.showCase.cost)} €
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDividerSmal}/>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      )
    } else if (this.state.menu) {
      return (<Paper elevation={0} square>
        <TopBar
          noUser
          noCart
          noUpdate
          noSchedule
          noBackoffice
          noDash
          noAlert
          noMemberCreate
          noFinance
          iconLeft={<ArrowBack />}
          onClickLeft={() => {
            this.setState({menu: undefined})
            if(this.state.memberSelected[0]) {
              this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum || 0, this.state.cart || [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, undefined, undefined)
            } else {
              this.deselect()
            }
          }}
          midTitle={'Menu Drinks'}
        />
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white', padding: '20px'}} square>
          <Grid container justifyContent='center' spacing={2}>
            {this.state.menu.map((item) => <Grid key={item.name} item xs={3}>
              <List className={this.props.classes.menuList}>
                <div key={item.idItem} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCart : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeader}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecCart}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </div>
              </List>
            </Grid>)}
          </Grid>
        </Paper>
      </Paper>)
    } else if (this.state.menu1) {
      return (<Paper elevation={0} square>
        <TopBar
          noUser
          noCart
          noUpdate
          noSchedule
          noBackoffice
          noDash
          noAlert
          noMemberCreate
          noFinance
          iconLeft={<ArrowBack />}
          onClickLeft={() => {
            this.setState({menu1: undefined})
            if(this.state.memberSelected[0]) {
              this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum || 0, this.state.cart || [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, undefined, undefined)
            } else {
              this.deselect()
            }
          }}
          midTitle={'Menu Flower'}
        />
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white', padding: '20px'}} square>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader}>Indica</Typography>
                {this.state.menu1.filter((el) => el.idCategory === 1 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                    <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                      <ListItemText
                        disableTypography
                        className={this.props.classes.menuListMenuScreen}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant='body2'
                              className={this.props.classes.menuListHeaderTv}
                              color='textPrimary'
                            >
                              {item.name}
                            </Typography>
                            <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                          </React.Fragment>
                        }
                        secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                      />
                    </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
            </Grid>
            <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader}>Hybrid</Typography>
                {this.state.menu1.filter((el) => el.idCategory === 2 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h2' align='center' className={this.props.classes.menuHeader}>Sativa</Typography>
              {this.state.menu1.filter((el) => el.idCategory === 3 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList} onClick={async event => {
                this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
              }}>
                <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                  <ListItemText
                    disableTypography
                    className={this.props.classes.menuListMenuScreen}
                    primary={
                      <React.Fragment>
                        <Typography
                          variant='body2'
                          className={this.props.classes.menuListHeaderTv}
                          color='textPrimary'
                        >
                          {item.name}
                        </Typography>
                        <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                      </React.Fragment>
                    }
                    secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                  />
                </ListItem>
                <Divider className={this.props.classes.menuDivider} />
              </List>)}
            </Grid>
            <Grid item xs={12}></Grid>
              {this.state.menu1.filter((el) => el.activeChoice === 1 && el.activeChoice === 1).map((item) => <Grid item xs={4} key={item.idItem}><List className={this.props.classes.menuList} onClick={async event => {
                this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
              }}>
                <ListItem className={this.props.classes.menuListChoice}>
                  <ListItemText
                    disableTypography
                    className={this.props.classes.menuListMenuScreen}
                    primary={
                      <React.Fragment>
                        <Typography
                          variant='body2'
                          className={this.props.classes.menuListHeaderTv}
                          color='textPrimary'
                        >
                          {item.name}
                        </Typography>
                        <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                      </React.Fragment>
                    }
                    secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                  />
                </ListItem>
              </List></Grid>)}
          </Grid>
        </Paper>
      </Paper>)
    } else if (this.state.menu2) {
      return (<Paper elevation={0} square>
        <TopBar
          noUser
          noCart
          noUpdate
          noSchedule
          noBackoffice
          noDash
          noAlert
          noMemberCreate
          noFinance
          iconLeft={<ArrowBack />}
          onClickLeft={() => {
            this.setState({menu2: undefined})
            if(this.state.memberSelected[0]) {
              this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum || 0, this.state.cart || [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, undefined, undefined)
            } else {
              this.deselect()
            }
          }}
          midTitle={'Menu Hash & Specials'}
        />
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white'}} square>
          <Grid container justifyContent='center' spacing={1}>
            <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader}>Pre-Rolled Joints</Typography>
                <List className={this.props.classes.menuList}>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            JOINT LIADO ESTÁNDAR
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>3 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>A partir de las cepas favoritas de nuestros socios</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            JOINT PACK
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>9 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>4 joint liados por el precio de 3</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            CBD Joint Liado
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>3 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Elaborado con nuestras mejores cepas de CBD</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader} style={{marginTop: 43}}>Specials</Typography>
                <List className={this.props.classes.menuList}>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            Vape Pen
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{'>'}45 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Batería recargable y cartucho de THC de 0,5 ml</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            Extracciones
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{'>'}27 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Rosin casero y Wax de alta concentración</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList} onClick={async event => {
                    const item = this.state.items.find(m => m.idItem === 193)
                    this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                  }}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            Moonrocks
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>25 €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Flores recubiertas de Rosin y polen</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader}>Hashish</Typography>
                {this.state.menu2.filter((el) => el.idCategory === 4 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List className={this.props.classes.menuList} key={item.idItem} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderHig}>CBD</Typography>
                {this.state.menu2.filter((el) => el.idCategory === 11 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
                <Typography variant='h2' align='center' className={this.props.classes.menuHeader} style={{marginTop: 42}}>Premium Hash</Typography>
                {this.state.menu2.filter((el) => el.idCategory === 19 & el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCartMenu : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
          </Grid>
        </Paper>
      </Paper>)
    }  else if (this.state.menu3) {
      return (<Paper elevation={0} square>
        <TopBar
          noUser
          noCart
          noUpdate
          noSchedule
          noBackoffice
          noDash
          noAlert
          noMemberCreate
          noFinance
          iconLeft={<ArrowBack />}
          onClickLeft={() => {
            this.setState({menu3: undefined})
            if(this.state.memberSelected[0]) {
              this.updateCart(__.getJsonSto('core').loginemail, this.state.memberSelected[0], this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName, this.state.cartAd, this.state.cartSum || 0, this.state.cart || [], this.members.find(m => m.idMember === this.state.memberSelected[0]).balance, undefined, undefined, undefined, undefined, undefined, undefined)
            } else {
              this.deselect()
            }
          }}
          midTitle={'Menu Drinks Events'}
        />
        <Paper className={this.props.classes.paperStyle} elevation={0} style={{background: 'white', padding: '20px'}} square>
          <Grid container justifyContent='center' spacing={2}>
            {this.state.menu3.map((item) => <Grid key={item.name} item xs={3}>
              <List className={this.props.classes.menuList}>
                <div key={item.idItem} onClick={async event => {
                  this.showDesc(item.idItem, item.name, item.parents, item.desc, item.imageLink, item.phenotype, item.effect, item.aroma, item.manufacturing, item.thc, item.cbd, item.cbg, item.cost, this.state.cart, this.state.cartSum)
                }}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabisCart + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlightCart : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListText}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeader}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                          <Typography className={this.props.classes.menuListHeaderSmal}>{__.formatNumber(item.cost)} €</Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecCart}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </div>
              </List>
            </Grid>)}
          </Grid>
        </Paper>
      </Paper>)
    } else if (this.state.donations) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noUpdate
            noSchedule
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleCloseDonations}
            midTitle={'Donations last 12 hours'}
          />
          <Paper className={this.props.classes.paperStyle} elevation={0} square>
            {this.state.donations && this.state.donations.length === 0 && <Typography>nothing yet...</Typography>}
            {this.state.donations && this.state.donations.length > 0 && this.state.donations.map((row) => {
              return <Accordion key={'donation' + row.idDonation}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel-${row.idDonation}-content`}
                  id={`panel-${row.idDonation}-header`}
                >
                  <Typography>
                    {mo(row.created).format(__.cfg('tsDateFormat'))} {__.formatNumber(row.amount)}€
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Member Id</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell>Created by</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {row.donateType}
                        </TableCell>
                        <TableCell>
                          {row.idMember}
                        </TableCell>
                        <TableCell>
                          {row.amount}
                        </TableCell>
                        <TableCell>
                          {row.comment}
                        </TableCell>
                        <TableCell>
                          {row.createdBy}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            }
          )}
          </Paper>
        </Paper>
      )
    } else if (this.state.memberHistory) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noUpdate
            noSchedule
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleCloseMemberHistory}
            midTitle={`History Member ${this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId} | Balance ${__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)}€`}
          />
          <Paper className={this.props.classes.paperStyle} elevation={0} square>
            {this.state.memberHistory && this.state.memberHistory.length === 0 && <Typography>nothing yet...</Typography>}
            {this.state.memberHistory && this.state.memberHistory.length > 0 && this.state.memberHistory.map((row) => {

                if(row.type === 'emission') {
                  return <Accordion key={'emission' + row.idEmission}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-${row.idEmission}-content`}
                      id={`panel-${row.idEmission}-header`}
                    >
                      <Typography>🟥 {mo(row.created).format(__.cfg('tsDateFormat'))} {__.formatNumber(row.amount)}€ Emission</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table aria-label="simple table">
                        <caption>
                          <span>Created by: {row.createdBy}</span>
                          <span style={{float:'right'}}>{__.formatNumber(row.amount)} € total</span>
                        </caption>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Cost</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.items.map((item) => {
                            return <TableRow key={item.idItem}>
                              <TableCell>
                                {item.idItem}
                              </TableCell>
                              <TableCell>
                                {item.category}
                              </TableCell>
                              <TableCell>
                                {item.name}
                              </TableCell>
                              <TableCell>
                                {item.quantity}
                              </TableCell>
                              <TableCell>
                                {__.formatNumber(item.totalCost)} €
                              </TableCell>
                            </TableRow>}
                          )}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                } else {
                  return <Accordion key={'donation' + row.idDonation}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-${row.idDonation}-content`}
                      id={`panel-${row.idDonation}-header`}
                    >
                      <Typography>🟩 {mo(row.created).format(__.cfg('tsDateFormat'))} {__.formatNumber(row.amount)}€ Donation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table aria-label="simple table">
                        <caption>
                          <span>Created by: {row.createdBy}</span>
                          <span style={{float:'right'}}>{__.formatNumber(row.amount)} € total</span>
                        </caption>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Comment</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {row.donateType}
                            </TableCell>
                            <TableCell>
                              {row.comment}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                }

              }
            )}
            {this.state.memberHistory && this.state.memberHistory.length > 0 && <Typography>(limited to last 10 records)</Typography>}
          </Paper>
        </Paper>
      )
    } else if (this.state.handoutModal) {
      return (
          <Paper className={this.props.classes.paperStyle} square>
            <Grid container spacing={0} direction='row'>
              <Grid item xs={12}>
                <Paper className={this.props.classes.paperStyle}>
                  <Typography gutterBottom variant='subtitle1'>Emission booked</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={this.props.classes.paperStyle}>
                <Typography gutterBottom variant='h4'>Member {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId} {this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName}</Typography>
                  {this.state.faceImgQuick && <div>
                    <img alt='face img' src={this.state.faceImgQuick} style={{display:'block', maxWidth: '100%', maxHeight: '275px', margin:'0 auto'}} />
                  </div>}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={this.props.classes.paperStyle}>
                  <Typography gutterBottom variant='body1'>Please hand out the following items:</Typography>
                  <TableContainer component={Paper} className={this.props.classes.dividerStyle}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.cart && this.state.cart.length > 0 && this.state.cart.map((item) => {
                          return <TableRow key={item.idItem}>
                            <TableCell>
                              {item.idItem}
                            </TableCell>
                            <TableCell>
                              {item.category}
                            </TableCell>
                            <TableCell>
                              {item.name}
                            </TableCell>
                            <TableCell>
                              {__.formatNumber(item.quantity)}
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={item.handout ? item.handout : false}
                                onClick={async event => this.handleEmitItem(event, item)}>
                                Handout {__.formatNumber(item.quantity)}
                              </Button>
                            </TableCell>
                          </TableRow>}
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                disabled={!this.state.emissionDone}
                onClick={async event => this.handleCloseHandout(event)}
              >All done - Goodbye and have a nice day!</Button>
            </Grid>
          </Paper>
      )
    } else if (this.state.imgModal) {
      return (
        <ModalCust
          fullScreen
          lbl={`Detail Image ${__.cap(this.state.imgModalType)}`}
          onClose={() => this.handleCloseImgModal()}
        >
          <img alt='img modal' src={this.state.imgModal} style={{display:'block', maxWidth: '100%', margin: '0 auto', marginTop: 20}} />
        </ModalCust>
      )
    } else if (this.state.tosModal) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noSchedule
            noUpdate
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            midTitle={'TOS'}
            iconLeft={<ArrowBack />}
            onClickLeft={() => this.handleCloseTosModal()}
            action={<Print />}
            isActionAllowed={true}
            onClick={() => {
              const oldTitle = document.title

              document.title = `TOS Member - ${this.state.tosMember.hashId} ${this.state.tosMember.firstName} ${this.state.tosMember.lastName}`
              window.print()

              document.title = oldTitle
            }}
          />
          <Paper className={this.props.classes.paperStyle} square>
            <TosContract
              docNumber={this.state.tosMember.docNumber}
              lastName={this.state.tosMember.lastName}
              firstName={this.state.tosMember.firstName}
              address={this.state.tosMember.address}
              email={this.state.tosMember.email}
              birthday={this.state.tosMember.birthday.format(__.cfg('tsYearFormat'))}
              monthlyG={this.state.tosMember.monthlyG}
              // residentChecked={this.state.residentChecked} TODO ?
              signatureDataUri={this.state.signatureDataUri}
              habitualChecked={this.state.tosMember.habitualChecked}
              medicalChecked={this.state.tosMember.medicalChecked}
              created={this.state.tosMember.created.format(__.cfg('tsYearFormat'))}
            />
            <Box displayPrint='none' >
              <Button onClick={() => this.handleCloseTosModal()} style={{marginTop:40}}>
                Back
              </Button>
            </Box>
          </Paper>
        </Paper>
      )
    } else if (this.members.length > 0) {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        {this.state.alert && <TopBar title noAlert noDash noFinance />}
        {(!this.state.alert && this.state.cart.length === 0) && <TopBar title noDash />}
        {(!this.state.alert && this.state.cart.length > 0) && <TopBar title noUser noUpdate noDash noAlert noMemberCreate noFinance />}
        <Paper className={this.props.classes.paperStyle} elevation={0} square>
          {this.state.cart.length === 0 && <DataGrid
            autoHeight
            paginationModel={{ page: this.state.pageMember, pageSize: this.state.pageSizeMember }}
            onPaginationModelChange={(model) => this.setState({pageSizeMember: model.pageSize, pageMember: model.page})}
            pageSizeOptions={[5, 10, 25]}
            getRowId={(row) => row.idMember}
            onRowSelectionModelChange={(newSelectionModel) => {
              //only update if new member is selected
              if(newSelectionModel && newSelectionModel[0] && newSelectionModel[0] !== this.state.memberSelected[0]) {
                this.setState({
                  faceImgQuick: undefined,
                  scannerActive: false
                })

                let memberStatusColor = this.members.find(m => m.idMember === newSelectionModel[0]).active ? 'yellow' : 'red'
                if(this.members.find(m => m.idMember === newSelectionModel[0]).active && this.members.find(m => m.idMember === newSelectionModel[0]).balance >= 0.1) {
                  memberStatusColor = 'green'
                }
                this.updateCart(__.getJsonSto('core').loginemail, newSelectionModel[0], this.members.find(m => m.idMember === newSelectionModel[0]).firstName, this.state.cartAd, 0, [], this.members.find(m => m.idMember === newSelectionModel[0]).balance)
                this.getFaceImgQuick('face', this.members.find(m => m.idMember === newSelectionModel[0]).faceImageId)
                this.setState({
                  memberSelected: newSelectionModel,
                  checkinFired: false,
                  memberStatusColor,
                  donateAmount: '',
                  cart: [],
                  cartSum: 0,
                  emitClicked: false,
                  scannerActive: false
                })
              }
            }}
            rowSelectionModel={this.state.memberSelected}
            components={{ Toolbar: QuickSearchToolbarMember }}
            componentsProps={{
              toolbar: {
                value: this.state.searchTextMember,
                onChange: (event) => this.requestSearchMember(event.target.value),
                clearSearch: () => this.requestSearchMember('')
              }
            }}
            columns={[
              /*{
                field: 'idMember',
                headerName: 'Id',
                type: 'string',
                width: 40
              },*/
              {
                field: 'hashId',
                headerName: 'Id',
                type: 'string',
                width: 75,
                renderCell: (cellValues) => <Button onClick={async event => this.showQr(cellValues.value)}>{cellValues.value}</Button>
              },
              {
                field: 'active',
                headerName: 'Active',
                align: 'center',
                width: 65,
                renderCell: (cellValues) => <Chip size='small' label='' style={{ backgroundColor: cellValues.value === true ? 'green' : 'red' }} />
              },
              {
                field: 'email',
                headerName: 'Email',
                width: 200,
                type: 'string'
              },
              {
                field: 'firstName',
                headerName: 'First Name',
                width: 200,
                type: 'string'
              },
              {
                field: 'lastName',
                headerName: 'Last Name',
                width: 200,
                type: 'string'
              },
              {
                field: 'balance',
                headerName: 'Balance',
                type: 'string',
                width: 75,
                hide: this.state.alert ? true : false,
                renderCell: (cellValues) => <span>{__.formatNumber(cellValues.value)} €</span>
              },
              {
                field: 'residentChecked',
                headerName: 'Resident',
                align: 'center',
                width: 70,
                renderCell: (cellValues) => <Chip size='small' label='' style={{ backgroundColor: cellValues.value === 1  ? 'green' : 'red' }} />
              },
              {
                field: 'docType',
                headerName: 'Doc Type',
                width: 130,
                renderCell: (cellValues) => {
                  if(cellValues.value === '1') return <div>Passport</div>
                  if(cellValues.value === '2') return <div>ID-Card</div>
                  if(cellValues.value === '3') return <div>Drivers-Licence</div>
                }
              },
              {
                field: 'docNumber',
                headerName: 'Doc Number',
                type: 'string',
                width: 130
              },
              /*{
                field: 'faceImageId',
                headerName: 'Face Image',
                type: 'string',
                renderCell: (cellValues) => <Button onClick={async event => this.getImage('face', cellValues.value)}>Show</Button>
              },*/
              {
                field: 'signatureImageId',
                headerName: 'TOS',
                type: 'string',
                renderCell: (cellValues) => <Button onClick={async event => this.showTos(cellValues.row)}>Show</Button>
              },
              {
                field: 'birthday',
                headerName: 'Birthday',
                width: 100,
                renderCell: (cellValues) => cellValues.value.format(__.cfg('tsYearFormat'))
              },
              {
                field: 'created',
                headerName: 'Created',
                width: 100,
                renderCell: (cellValues) => cellValues.value.format(__.cfg('tsYearFormat'))
              },
              {
                field: 'membershipValidUntil',
                headerName: 'Valid Until',
                width: 100,
                renderCell: (cellValues) => cellValues.value.isValid() ? cellValues.value.format(__.cfg('tsYearFormat')) : 'never'
              },
              {
                field: 'lastCheckin',
                headerName: 'Last Check-In',
                width: 130,
                renderCell: (cellValues) => cellValues.value ? cellValues.value.format(__.cfg('tsDateFormat')) : 'never'
              }
            ]}
            rows={this.state.members}
            className={this.props.classes.dashTable}
          />}
          {this.state.cart.length === 0 && <Grid container spacing={5} style={{marginBottom: 35}}>
            {this.state.memberSelected.length === 0 && <Grid item xs={12}> 
               <Button 
                fullWidth 
                variant='outlined' 
                disabled={this.state.scannerBtnDisabled} 
                onClick={() => {
                  this.setState({scannerActive: !this.state.scannerActive, scannerBtnDisabled: true})
                  
                  // ios prepare sound with empty wav (fuck ios)
                  scanSound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";

                  // fix ios cam bug
                  setTimeout(() => {
                    this.setState({scannerBtnDisabled: false})
                  }, 2200);
                }}>
                  {this.state.scannerActive && <>
                    📷 Disable QR code scanner
                  </>}
                  {!this.state.scannerActive && <>
                    📷 Scan QR code
                  </>}
                </Button>
                {this.state.scannerActive && <div onClick={() => {
                    this.setState({
                      qrCamFacingMode: !this.state.qrCamFacingMode
                    })
                  }}><QrReader
                  key={this.state.qrCamFacingMode === true ? 'key-environment' : 'key-user'}
                  constraints={{
                    facingMode: this.state.qrCamFacingMode === true ? 'environment' : 'user'
                  }}
                  scanDelay={300}
                  onResult={(result) => {
                    if(result && result.text && result.text.length > 5) {
                      const scanData = result.text.split('|')
                      if(scanData.length === 2 && scanData[0] === 'grdn')  {
                        const member = this.members.find(m => m.hashId === scanData[1])
                        if(member && Number.isInteger(member.idMember)) {
                          let memberStatusColor = member.active ? 'yellow' : 'red'
                          if(member.active && member.balance >= 0.1) {
                            memberStatusColor = 'green'
                          }
                          this.setState({
                            memberSelected: [parseInt(member.idMember)],
                            scannerActive: false,
                            scannerBtnDisabled: false,
                            searchTextMember: scanData[1],
                            donateAmount: '',
                            cart: [],
                            cartSum: 0,
                            emitClicked: false,
                            memberStatusColor
                          })
                          this.requestSearchMember(scanData[1])
                          this.getFaceImgQuick('face', member.faceImageId)
                          this.updateCart(__.getJsonSto('core').loginemail, parseInt(member.idMember), this.members.find(m => m.idMember === parseInt(member.idMember)).firstName, this.state.cartAd, 0, [], this.members.find(m => m.idMember === parseInt(member.idMember)).balance)
                          this.playScanSound()
                        } else {
                          this.setState({
                            scannerActive: false,
                            scannerBtnDisabled: false,
                            snack: 'Member not found...'
                          })
                        }
                      }
                    }
                  }}
                  containerStyle={{ width: 128, margin: '0 auto' }}
                />
                </div>}
            </Grid>}
            {this.state.memberSelected.length && <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant='outlined'
                onClick={async event => this.deselect()}
                disabled={this.state.memberSelected.length === 0}
                style={{marginBottom: 32}}
              >
                ↩️ Deselect&nbsp;{this.state.memberSelected[0] && <span> Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
              </Button>
              {__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && <div>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.deactivate(this.state.memberSelected[0], false)}
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                  style={{marginBottom: 32}}
                >
                  ⛔ Deactivate&nbsp;{this.state.memberSelected[0] && <span>Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
                </Button>
              </div>}
              {__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && this.state.memberSelected.length > 0 && <div>
                <FormControl fullWidth>
                  <InputLabel id='donation-type-selector'>Type</InputLabel>
                  <Select
                    labelId='donation-type-selector'
                    id='donation-type'
                    value={this.state.donationType}
                    disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                    label='Type'
                    onChange={this.handleDonationTypeChange}
                  >
                    <MenuItem value={1}>💵 Cash</MenuItem>
                    <MenuItem value={2}>₿ Bitcoin</MenuItem>
                    <MenuItem value={3}>❗ Member Discount</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label='Donate in €uro'
                  margin='normal'
                  value={this.state.donateAmount}
                  variant='outlined'
                  inputProps={{ inputMode: 'numeric' }}
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                  onChange={(event) => {
                    const numberOnly = event.target.value.replace(/\D/g,'')

                    if((Number.isInteger(parseInt(numberOnly)) && parseInt(numberOnly) !== 0 && parseInt(numberOnly) <= 100) || event.target.value === '') {
                      this.setState({
                        donateAmount: numberOnly
                      })
                    }
                  }}
                />
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.showDonatePop(event)}
                  disabled={this.state.memberSelected.length === 0 || !this.state.donateAmount || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                >
                  💰 Donate&nbsp;{this.state.donateAmount && <span>{this.state.donateAmount}€</span>}
                </Button>
              </div>}
              {this.state.memberSelected.length !== 0 && !__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && !this.state.alert && <div>
                <Button
                  fullWidth
                  variant='outlined'
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                  onClick={async event => this.showMenu(event)}
                >
                  🪞 Show Menu Drinks
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                  onClick={async event => this.showMenu(event, 1)}
                  style={{marginTop: 32}}
                >
                  🪞 Show Menu Flower
                </Button>
              </div>}
            </Grid>}
            {this.state.memberSelected.length &&<Grid item xs={12} sm={4}>
              <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.checkin(event)}
                  disabled={this.state.memberSelected.length === 0 || this.state.checkinFired === true || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                  style={{marginBottom: 32}}
                >
                  🏠 Arrival&nbsp;{this.state.memberSelected[0] && <span> Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
              </Button>
              {__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && <div>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.activate(this.state.memberSelected[0], true)}
                  disabled={this.state.memberSelected.length === 0 || this.members.find(m => m.idMember === this.state.memberSelected[0]).active}
                  style={{marginBottom: 32}}
                >
                  ✅ Activate&nbsp;{this.state.memberSelected[0] && <span> Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
                </Button>
              </div>}
              {__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && !this.state.alert && <div>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.showDonations(event)}
                >
                  🧰 Show Donations
                </Button>
              </div>}
              {this.state.memberSelected.length !== 0 && !__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && <div>
                {!this.state.alert && <Button
                  fullWidth
                  variant='outlined'
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                  onClick={async event => this.showMenu(event, 2)}
                  style={{marginBottom: 32}}
                >
                  🪞 Show Menu Hash & Specials
                </Button>}
              </div>}
              {this.state.memberSelected.length !== 0 && !__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && <div>
                {!this.state.alert && <Button
                  fullWidth
                  variant='outlined'
                  disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                  onClick={async event => this.showMenu(event, 3)}
                >
                  🍹 Show Menu Events
                </Button>}
              </div>}
            </Grid>}
            {this.state.memberSelected.length && <Grid item xs={12} sm={4}>
              {!this.state.alert &&
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.getHistory(event)}
                  disabled={this.state.memberSelected.length === 0}
                  style={{marginBottom: 32}}
                >
                  📜 History&nbsp;{this.state.memberSelected[0] && <span> Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
                </Button>}
                {__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && <div>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={async event => this.delete(this.state.memberSelected[0])}
                    disabled={this.state.memberSelected.length === 0 || this.members.find(m => m.idMember === this.state.memberSelected[0]).active || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance > 0}
                    style={{marginBottom: 32}}
                  >
                    🔴 Delete&nbsp;{this.state.memberSelected[0] && <span> Member: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}</span>}
                  </Button>
                </div>}
                {this.state.faceImgQuick && <div>
                  <img alt='face img' src={this.state.faceImgQuick} style={{display:'block', maxWidth: '100%', maxHeight: '160px', margin:'0 auto'}} />
                </div>}
                {this.state.memberSelected.length !== 0 && !this.state.faceImgQuick && <div style={{marginTop: 32, marginBottom: 32, height: 160, width: 150, display: 'block'}}></div>}
              </Grid>}
          </Grid>}
          {this.state.cart.length > 0 && <Grid container spacing={10}>
            <Grid item xs={12} sm={4}>
              <Typography variant='h5'>
                Cart selected
              </Typography>
              <Typography gutterBottom variant='subtitle1'>
                Id: {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}<br />
                Name: {this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName}<br />
                Balance: {__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance)} €<br />
                Balance {(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance - this.state.cartSum) > 0 ? 'free' : 'overdraft'}: {__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance - this.state.cartSum)} €<br />
                Cart Total: {__.formatNumber(this.state.cartSum)} €
              </Typography>
              <Button
                fullWidth
                variant='outlined'
                onClick={async event => this.deselect()}
                disabled={this.state.memberSelected.length === 0}
              >
                ↩️ Deselect
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              {!this.state.alert && <Button
                fullWidth
                variant='outlined'
                disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                onClick={async event => this.showMenu(event)}
                style={{marginBottom: 32}}
              >
                🪞 Show Menu Drinks
              </Button>}
              {!this.state.alert && <Button
                fullWidth
                variant='outlined'
                disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                onClick={async event => this.showMenu(event, 1)}
                style={{marginBottom: 32}}
              >
                🪞 Show Menu Flowers
              </Button>}
              {!this.state.alert && <Button
                fullWidth
                variant='outlined'
                disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                onClick={async event => this.showMenu(event, 2)}
                style={{marginBottom: 32}}
              >
                🪞 Show Menu Hash & Specials
              </Button>}
              {!this.state.alert && <Button
                fullWidth
                variant='outlined'
                disabled={this.state.memberSelected.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin || !this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
                onClick={async event => this.showMenu(event, 3)}
              >
                🍹 Show Menu Events
              </Button>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <img alt='face img' src={this.state.faceImgQuick} style={{display:'block', maxWidth: '100%', maxHeight: '193px', margin:'0 auto'}} />
              {!this.state.alert &&
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={async event => this.getHistory(event)}
                  disabled={this.state.memberSelected.length === 0}
                >
                  📜 History
                </Button>}
            </Grid>
          </Grid>}
          {this.state.memberSelected.length !== 0 && !__.cfg('lobbyAccounts').includes(__.getJsonSto('core').loginemail) && this.members.find(m => m.idMember === this.state.memberSelected[0]).balance > 0.1 && this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin && this.members.find(m => m.idMember === this.state.memberSelected[0]).lastCheckin.isSameOrAfter(mo().subtract(8, 'hours')) && <span>
            <DataGrid
              autoHeight
              checkboxSelection
              paginationModel={{ page: this.state.pageItem, pageSize: this.state.pageSizeItem }}
              onPaginationModelChange={(model) => this.setState({pageSizeItem: model.pageSize, pageItem: model.page})}
              pageSizeOptions={[5, 10, 25]}
              processRowUpdate={this.handleRowUpdate}
              getRowId={(row) => row.idItem}
              onRowSelectionModelChange={(newSelectionModel) => {
                this.setState({
                  cartSelection: newSelectionModel
                })
              }}
              rowSelectionModel={this.state.cartSelection}
              components={{ Toolbar: QuickSearchToolbarItem }}
              componentsProps={{
                toolbar: {
                  value: this.state.searchTextItem,
                  onChange: (event) => this.requestSearchItem(event.target.value),
                  clearSearch: () => this.requestSearchItem('')
                }
              }}
              columns={[
                /*{
                  field: 'idItem',
                  headerName: 'Id',
                  width: 60,
                  type: 'string'
                },*/
                {
                  field: 'category',
                  headerName: 'Category',
                  width: 120,
                  type: 'string'
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1,
                  minWidth: 220,
                  type: 'string'
                },
                {
                  field: 'cost',
                  headerName: 'Cost',
                  width: 60,
                  type: 'string',
                  hide: this.state.alert ? true : false,
                  renderCell: (cellValues) => <span>{__.formatNumber(cellValues.value)} €</span>
                },
                {
                  field: 'desc',
                  headerName: 'Description',
                  width: 90,
                  renderCell: (cellValues) => <Button disabled={this.state.memberSelected.length === 0} onClick={async event => {
                    this.showDesc(cellValues.row.idItem, cellValues.row.name, cellValues.row.parents, cellValues.value, cellValues.row.imageLink, cellValues.row.phenotype, cellValues.row.effect, cellValues.row.aroma, cellValues.row.manufacturing, cellValues.row.thc, cellValues.row.cbd, cellValues.row.cbg, cellValues.row.cost, this.state.cart, this.state.cartSum)
                  }}>Show</Button>
                },
                {
                  field: 'aroma',
                  headerName: 'Aroma',
                  width: 200,
                  type: 'string'
                },
                {
                  field: 'effect',
                  headerName: 'Effect',
                  width: 200,
                  type: 'string'
                },
                {
                  field: 'thc',
                  headerName: 'THC',
                  width: 60,
                  type: 'string',
                  renderCell: (cellValues) => <span>{__.formatNumber(cellValues.value)} %</span>
                },
                /*{
                  field: 'cbd',
                  headerName: 'CBD',
                  width: 50,
                  type: 'number'
                },
                {
                  field: 'cbg',
                  headerName: 'CBG',
                  width: 50,
                  type: 'number'
                },*/
                {
                  field: 'quantity',
                  headerName: 'Quantity',
                  type: 'string',
                  width: 80,
                  editable: true
                }
              ]}
              rows={this.state.items}
              className={this.props.classes.dividerStyle}
            />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                color='primary'
                onClick={async event => this.handleAddCart(event)}
                disabled={ this.state.memberSelected.length === 0 || this.state.cartSelection.length === 0 || !this.members.find(m => m.idMember === this.state.memberSelected[0]).active || this.members.find(m => m.idMember === this.state.memberSelected[0]).balance < 0.1}
              >
                Add to cart
              </Button>
              <Button
                style={{float:'right'}}
                onClick={async event => this.handleClearCart(event)}
                disabled={ this.state.cartSelection.length !== 0 }
              >
                Empty cart
              </Button>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TableContainer component={Paper} className={this.props.classes.dividerStyle}>
                <Table aria-label="simple table">
                  <caption style={{textAlign:'right'}}>{__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance )}€ balance | {__.formatNumber(this.state.cartSum)}€ total | {__.formatNumber(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance - this.state.cartSum)}€ {(this.members.find(m => m.idMember === this.state.memberSelected[0]).balance - this.state.cartSum) > 0 ? 'free' : 'overdraft'}</caption>
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total in €uro</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cart && this.state.cart.length > 0 && this.state.cart.map((item) => {
                      const element = this.items.find(e => e.idItem === item.idItem)
                      return <TableRow key={item.idItem}>
                        <TableCell>
                          {element.category}
                        </TableCell>
                        <TableCell>
                          {element.name}
                        </TableCell>
                        <TableCell>
                          {__.formatNumber(element.cost)} €
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant='standard'
                            type='text'
                            size='small'
                            inputProps={{ inputMode: 'decimal' }}
                            value={item.quantity}
                            onChange={async event => this.handleChangeCart(event, 'quantity', item)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant='standard'
                            type='text'
                            inputProps={{ inputMode: 'decimal' }}
                            size='small'
                            value={item.total}
                            onChange={async event => this.handleChangeCart(event, 'total', item)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={async event => this.handleRmItemCart(event, item)}>
                            X
                          </Button>
                        </TableCell>
                      </TableRow>}
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant='h3' align='center' style={{marginTop: 16, color: this.state.memberStatusColor}} >
                {this.members.find(m => m.idMember === this.state.memberSelected[0]).hashId}
              </Typography>
                <img alt='face img' src={this.state.faceImgQuick} style={{display:'block', maxWidth: '100%', maxHeight: '200px', margin:'0 auto'}} />
              <Typography variant='body1' align='center'>{this.members.find(m => m.idMember === this.state.memberSelected[0]).firstName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={async event => this.handleFinalizeCart(event)}
                  disabled={ this.state.emitClicked || this.state.memberSelected.length === 0 || this.state.cart.length === 0 || this.state.cartSum === 0 || this.state.cartSum > this.members.find(m => m.idMember === this.state.memberSelected[0]).balance}
                >
                  Emit
                </Button>
            </Grid>
          </Grid>
          </span>}
        </Paper>
      </div>
    } else {
      return <LinearProgress />
    }
  }
}

export default withStyles({
    colorFormat,
    gridWrap,
    gridWrapPaper,
    paperStyle,
    dividerStyle,
    dashTable,
    menuList,
    menuListTextTv,
    menuDivider,
    menuDividerSmal,
    showCaseListHeader,
    showCaseListType,
    showCaseListTypeBig,
    showCaseItemName,
    showCaseItemParents,
    menuHeader,
    menuHeaderHig,
    menuListText,
    menuListHeader,
    menuListHeaderSmal,
    menuListSecCart,
    menuListHighlightCart,
    menuListHighlightCartMenu,
    menuListHighlightSpannabisCart,
    qrCodeWrap,
    menuListMenuScreen,
    menuListHeaderTv,
    menuListSecTv,
    menuListChoice
  })(DashView)
