// import * as mo from 'moment'
import { StoBase } from './Lib'
//import __ from '../util'

export default class Cart extends StoBase {
  constructor (cx, token) {
    super('Cart', cx, 'd43b70b1-e5cd-4b0a-bd4f-a659b72ccdb4')
    this.token = token
    this.getStaffCarts = this.getStaffCarts.bind(this)
    this.getCart = this.getCart.bind(this)
    this.clearShowCase = this.clearShowCase.bind(this)
    this.finalizeCart = this.finalizeCart.bind(this)
    this.deselect = this.deselect.bind(this)
  }

  async getStaffCarts () {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart',
        method: 'GET'
      },
      token
    )
    return pld
  }

  async getCart (owner) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart',
        method: 'GET',
        params: {
          owner
        }
      },
      token
    )
    return pld
  }

  async updateCart (owner, idMember, firstNameMember, cartAd, cartSum, items, balanceMember, showCase, showComplete, menu, menu1, menu2, menu3) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart',
        method: 'POST',
        data: { owner, idMember, firstNameMember, cartAd, cartSum, items, balanceMember, showCase, showComplete, menu, menu1, menu2, menu3 }
      },
      token
    )
    return pld
  }

  async clearShowCase (owner) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart/showcase/clear',
        method: 'POST',
        data: { owner }
      },
      token
    )
    return pld
  }

  async finalizeCart (idMember, cart, owner) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart/finalize',
        method: 'POST',
        data: { idMember, cart, owner }
      },
      token
    )
    return pld
  }

  async deselect (owner) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'cart/deselect',
        method: 'POST',
        data: { owner }
      },
      token
    )
    return pld
  }
}
